import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

function BlogsView() {
    return (
        <>
            {" "}
            <Header/>
            <section className="blogs-section pa-y4 pb-2 pt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d_flex">
                                <div className="common-heading text-start mb-0">
                                    <h2>Blog Title appear here</h2>
                                    <p>Category name here</p>
                                </div>
                                <div className="media">
                                    <img src="/static/images/author.svg" alt="Author" className="img-fluid"/>
                                    <div className="media-body">
                                        <h6>Team CreativeProxies</h6>
                                        <p>March 2, 2023 . 4 Min Read</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="blogs-main-preview">
                                <img src="/static/images/blogs-big.svg" alt="Blogs" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-1 col-md-2 col-sm-12 col-12">
                            <div className="partial-social-icon">
                                <ul>
                                    <li>
                                        <a href="#">
                                            <img src="/static/images/telegram.svg" alt="Blogs" className="img-fluid"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="/static/images/linkedin.svg" alt="Blogs" className="img-fluid"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="/static/images/insta.svg" alt="Blogs" className="img-fluid"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="/static/images/twitter.svg" alt="Blogs" className="img-fluid"/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-11 col-md-10 col-sm-12 col-12">
                            <div className="blogs-details-txt">
                                <h5>Heading 1</h5>
                                <p>
                                    Praesent aliquet lorem ipsum, non rhoncus elit placerat malesuada. Nullam vitae
                                    sagittis dolor. Pellentesque eu orci accumsan,
                                    feugiat tellus ac, commodo ex. Proin eleifend commodo iaculis. Morbi vel ipsum
                                    purus. Vestibulum egestas magna at nibh laoreet, et
                                    placerat dui euismod. Fusce accumsan tincidunt neque, sed lobortis mauris tristique
                                    sollicitudin.
                                </p>
                                <h5>Heading 2</h5>
                                <p>Bullet Points Starter :</p>
                                <ul>
                                    <li>
                                        <i className="fa fa-circle"/> This Site and all its Contents are intended solely
                                        for personal, non-commercial use.{" "}
                                    </li>
                                    <li>
                                        <i className="fa fa-circle"/> Except as expressly provided, nothing within the
                                        Site shall be construed as conferring any license
                                        under our or any third party's intellectual property rights, whether by
                                        estoppel, implication, waiver, or otherwise.{" "}
                                    </li>
                                    <li>
                                        <i className="fa fa-circle"/> Without limiting the generality of the foregoing,
                                        you acknowledge and agree that all content
                                        available through and used to operate the Site and its services is protected by
                                        copyright, trademark, patent, or other proprietary
                                        rights.
                                    </li>
                                    <li>
                                        <i className="fa fa-circle"/> You agree not to: (a) modify, alter, or deface any
                                        of the trademarks, service marks, trade dress
                                        (collectively "Trademarks") or other intellectual property made available by us
                                        in connection with the Site; (b) hold yourself out
                                        as in any way sponsored by, affiliated with, or endorsed by us, or any of our
                                        affiliates or service providers; (c) use any of the.
                                    </li>
                                    <li>
                                        <i className="fa fa-circle"/> Trademarks or other content accessible through the
                                        Site for any purpose other than the purpose for
                                        which we have made it available to you; (d) defame or disparage us, our
                                        Trademarks, or any{" "}
                                    </li>
                                    <li>
                                        <i className="fa fa-circle"/> Aspect of the Site; and (e) adapt, translate,
                                        modify, decompile, disassemble, or reverse engineer
                                        the Site or any software or programs used in connection with it or its products
                                        and services.
                                    </li>
                                </ul>
                                <div className="blogs-video">
                                    <img src="/static/images/blogs-video.svg" alt="blogs-video" className="img-fluid"/>
                                </div>
                                <div className="blogs-quote">
                                    <h5>Quote</h5>
                                    <p>
                                        “Proin posuere enim nec odio eleifend ornare. Vivamus at dui pharetra, volutpat
                                        nisl quis, tristique odio. Sed tincidunt finibus
                                        dolor ac fringilla.”
                                    </p>
                                </div>
                                <h5>Blog Closing</h5>
                                <p>Thank you for reading our blog post.</p>
                                <div className="blogs-tags">
                                    <h6>Tags:</h6>
                                    <span>Test</span>
                                    <span>Blog</span>
                                    <span>Proxy</span>
                                    <span>Test</span>
                                    <span>Blog</span>
                                    <span>Proxy</span>
                                    <span>Test</span>
                                    <span>Blog</span>
                                    <span>Proxy</span>
                                    <span>Test</span>
                                    <span>Blog</span>
                                    <span>Proxy</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="blogs-head">
                            <hr/>
                            <h5>Read More Blogs</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-6 col-lg-4">
                            <div className="blog-box-wrap">
                                <img src="/static/images/blog-img.svg" alt="Blog" className="img-fluid"/>
                                <div className="blog-txt">
                                    <h5>Blog Title appear here</h5>
                                    <p>Category name here</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                            <div className="blog-box-wrap">
                                <img src="/static/images/blog-img.svg" alt="Blog" className="img-fluid"/>
                                <div className="blog-txt">
                                    <h5>Blog Title appear here</h5>
                                    <p>Category name here</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                            <div className="blog-box-wrap">
                                <img src="/static/images/blog-img.svg" alt="Blog" className="img-fluid"/>
                                <div className="blog-txt">
                                    <h5>Blog Title appear here</h5>
                                    <p>Category name here</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
}

export default BlogsView;
