import React from "react";

function Footer() {
    return (
        <footer className="footer_section">
            <div className="container border-tops">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-8 col-12">
                        <div className="ftr-logo">
                            <a href="/">
                                <img src="/static/images/logo.svg" alt="Logo" className="img-fluid"/>
                            </a>
                            <p>
                                Copyright © 2023 Creative Proxies. <br/>
                                All rights reserved.
                            </p>
                            <a href="https://discord.gg/creativeproxies" className="discord-button">
                                <img src="/static/images/discord-icon.svg" alt="Discord" className="img-fluid"/> Join
                                Our Discord
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-4 col-6">
                        <div className="ftr-links">
                            <h6>Explore</h6>
                            <ul>
                                <li>
                                    <a href="/location-checker">IP Location Checker</a>
                                </li>
                                <li>
                                    <a href="/compatibility-checker">IPv6 Comptability</a>
                                </li>
                                {false && <li>
                                    <a href="/blog">Blogs</a>
                                </li>}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                        <div className="ftr-links">
                            <h6>Resources</h6>
                            <ul>
                                {false &&
                                <li>
                                    <a href="/faq">FAQs</a>
                                </li>}
                                <li>
                                    <a href="/branding">Branding</a>
                                </li>
                                <li>
                                    <a href="/network-information">Network Information</a>
                                </li>
                                <li>
                                    <a href="/imprint">Imprint</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-6 col-6">
                        <div className="ftr-links">
                            <h6>Support</h6>
                            <ul>
                                <li>
                                    <a href="/tos">Terms of Service</a>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <a href="/privacy">Privacy Policy</a>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <a href="/cookies">Cookies Policy</a>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <a href="/refund">Refund Policy</a>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <a href="/acceptable">Acceptable Use Policy</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
