import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

function CompCheckerError() {
    return (
        <>
            {" "}
            <Header/>
            <section className="location-checker-section pa-y4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common-heading">
                                <p className="m-0 mb-1">IPv6 COMPATIBILITY CHECKER</p>
                                <h2>
                                    Check IPv6 Compatibility of <br/>
                                    any domain <span className="blu">Free</span> of Cost
                                </h2>
                                <p>
                                    Just enter a domain and this tool instantly checks the <br/> IPv6 Compatibility
                                    against a given domain.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className="location-checker-tool-wrap location-error">
                                <form action>
                                    <div className="form-group form-group-input">
                                        <input
                                            type="text"
                                            placeholder="Enter a valid domain"
                                            className="form-control"
                                            style={{minHeight: "3rem", paddingLeft: "3rem"}}
                                        />
                                        <img src="/static/images/link-icon.svg" alt="Link" className="img-fluid"/>
                                    </div>
                                    <div className="d-flex">
                                        <div>
                                            <button className="btn btn-submit" type="submit">
                                                Check Compatibility{" "}
                                            </button>
                                            <span className="error-message">Please Enter Valid Input</span>
                                        </div>
                                        <p>
                                            By using our tool, you agree our <br/>
                                            Terms of Service and Privacy Policy
                                        </p>
                                    </div>
                                </form>
                            </div>
                            <div className="netowork-info-table-box location-check-table">
                                <h5>Your Results</h5>
                                <table>
                                    <tbody>
                                    <tr>
                                        <th colSpan={2}>IPv6 Compatibility Info</th>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} rowSpan={4} style={{textAlign: "center", height: 120}}>
                                            No Records to Display
                                        </td>
                                    </tr>
                                    <tr/>
                                    <tr/>
                                    <tr/>
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-more">
                                <p>
                                    Check out our IPv6 Compatibility Checker{" "}
                                    <a href="#">
                                        View Tool <img src="/static/images/arrow-right-blu.svg" alt="a"
                                                       className="img-fluid"/>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
}

export default CompCheckerError;
