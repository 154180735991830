import axios from "axios";
import {QueryClient} from "react-query";

const defaultQuery = async ({queryKey}) => {
    const {data} = await axios.get(queryKey[0], {withCredentials: true})
    return data
}

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            queryFn: defaultQuery,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
        }
    }
})
