import React from "react";
import "./App.css";
import Router from "./routes";
import {LicenseInfo} from '@mui/x-license-pro';

function App() {
    LicenseInfo.setLicenseKey('fcf048b8df66305299980c8a7ea462e3Tz01MDc1OSxFPTE2OTQ2OTAyOTQzOTMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
    return <Router/>;
}

export default App;
