import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

function Faq() {
    return (
        <>
            {" "}
            <Header/>
            <section className="faq-section pa-y4 pt-0">
                <div className="container">
                    {/* common heading @S */}
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10 col-12">
                            <div className="common-heading text-start">
                                <h2>Frequently Asked Questions</h2>
                                <p>All your questions, Answered.</p>
                            </div>
                        </div>
                    </div>
                    {/* common heading @E */}
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10 col-12">
                            <div className="faq-search-box">
                                <img src="/static/images/search-icon.svg" alt="Search Icon" className="img-fluid"/>
                                <input type="text" placeholder="Search for your questions using a keyword or a phrase"
                                       className="form-control"/>
                            </div>
                            <div className="faq-ask-wrap">
                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingOne">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseOne"
                                                aria-expanded="false"
                                                aria-controls="flush-collapseOne"
                                            >
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In scelerisque
                                                ?
                                            </button>
                                        </h2>
                                        <div
                                            id="flush-collapseOne"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingOne"
                                            data-bs-parent="#accordionFlushExample"
                                        >
                                            <div className="accordion-body">
                                                <p>
                                                    Mauris ullamcorper metus sit amet cursus sagittis. Ut egestas
                                                    aliquet est nec rutrum. Nullam quis porta ex. Fusce tristique
                                                    nisl efficitur augue pellentesque tincidunt. Nam at interdum nunc,
                                                    nec dictum enim. Aliquam non odio faucibus, volutpat erat
                                                    a, finibus urna. Fusce fringilla leo eget risus ullamcorper suscipit
                                                    at et libero.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingTwo">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="flush-collapseTwo"
                                            >
                                                Ut at efficitur dui. Proin sollicitudin dictum dui in ultrices?
                                            </button>
                                        </h2>
                                        <div
                                            id="flush-collapseTwo"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingTwo"
                                            data-bs-parent="#accordionFlushExample"
                                        >
                                            <div className="accordion-body">
                                                <p>
                                                    Mauris ullamcorper metus sit amet cursus sagittis. Ut egestas
                                                    aliquet est nec rutrum. Nullam quis porta ex. Fusce tristique
                                                    nisl efficitur augue pellentesque tincidunt. Nam at interdum nunc,
                                                    nec dictum enim. Aliquam non odio faucibus, volutpat erat
                                                    a, finibus urna. Fusce fringilla leo eget risus ullamcorper suscipit
                                                    at et libero.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingThree">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseThree"
                                                aria-expanded="false"
                                                aria-controls="flush-collapseThree"
                                            >
                                                Ut tempor urna non risus lacinia malesuada?
                                            </button>
                                        </h2>
                                        <div
                                            id="flush-collapseThree"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingThree"
                                            data-bs-parent="#accordionFlushExample"
                                        >
                                            <div className="accordion-body">
                                                <p>
                                                    Mauris ullamcorper metus sit amet cursus sagittis. Ut egestas
                                                    aliquet est nec rutrum. Nullam quis porta ex. Fusce tristique
                                                    nisl efficitur augue pellentesque tincidunt. Nam at interdum nunc,
                                                    nec dictum enim. Aliquam non odio faucibus, volutpat erat
                                                    a, finibus urna. Fusce fringilla leo eget risus ullamcorper suscipit
                                                    at et libero.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingFour">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseFour"
                                                aria-expanded="false"
                                                aria-controls="flush-collapseFour"
                                            >
                                                Suspendisse at imperdiet eros. In sed sagittis leo?
                                            </button>
                                        </h2>
                                        <div
                                            id="flush-collapseFour"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingFour"
                                            data-bs-parent="#accordionFlushExample"
                                        >
                                            <div className="accordion-body">
                                                <p>
                                                    Mauris ullamcorper metus sit amet cursus sagittis. Ut egestas
                                                    aliquet est nec rutrum. Nullam quis porta ex. Fusce tristique
                                                    nisl efficitur augue pellentesque tincidunt. Nam at interdum nunc,
                                                    nec dictum enim. Aliquam non odio faucibus, volutpat erat
                                                    a, finibus urna. Fusce fringilla leo eget risus ullamcorper suscipit
                                                    at et libero.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingFive">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseFive"
                                                aria-expanded="false"
                                                aria-controls="flush-collapseFive"
                                            >
                                                Praesent in molestie nibh, sed accumsan lorem?
                                            </button>
                                        </h2>
                                        <div
                                            id="flush-collapseFive"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingFive"
                                            data-bs-parent="#accordionFlushExample"
                                        >
                                            <div className="accordion-body">
                                                <p>
                                                    Mauris ullamcorper metus sit amet cursus sagittis. Ut egestas
                                                    aliquet est nec rutrum. Nullam quis porta ex. Fusce tristique
                                                    nisl efficitur augue pellentesque tincidunt. Nam at interdum nunc,
                                                    nec dictum enim. Aliquam non odio faucibus, volutpat erat
                                                    a, finibus urna. Fusce fringilla leo eget risus ullamcorper suscipit
                                                    at et libero.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingSix">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseSix"
                                                aria-expanded="false"
                                                aria-controls="flush-collapseSix"
                                            >
                                                Donec interdum ex tristique justo lacinia condimentum id eu leo?
                                            </button>
                                        </h2>
                                        <div
                                            id="flush-collapseSix"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingSix"
                                            data-bs-parent="#accordionFlushExample"
                                        >
                                            <div className="accordion-body">
                                                <p>
                                                    Mauris ullamcorper metus sit amet cursus sagittis. Ut egestas
                                                    aliquet est nec rutrum. Nullam quis porta ex. Fusce tristique
                                                    nisl efficitur augue pellentesque tincidunt. Nam at interdum nunc,
                                                    nec dictum enim. Aliquam non odio faucibus, volutpat erat
                                                    a, finibus urna. Fusce fringilla leo eget risus ullamcorper suscipit
                                                    at et libero.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="faq-more">
                                <p>
                                    Didn’t find an answer? <a href="#">Contact Us</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
}

export default Faq;
