import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

function Index() {
    return (
        <>
            <Header/>
            <div>
                {/* hero section start */}
                <section className="hero-section pa-y4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-12">
                                <div className="hero-txt-wrap">
                                    <h6>WELCOME TO CREATIVE PROXIES</h6>
                                    <h1>
                                        We are the <span className="blu">Fastest</span> and the <span
                                        className="grn">Most</span> <br/>
                                        <span className="grn">Reliable</span> Proxy Service since 2020
                                    </h1>
                                    <p>
                                        IPv4 and IPv6 IPs, Rotating and Static. <br/> Over 1000 locations and many other
                                        features
                                    </p>
                                    <div className="hero_btn">
                                        <a href="https://app.creativeproxies.com/register">
                                            Get Started <img src="/static/images/arrow-right.svg" alt="Light Icon"
                                                             className="img-fluid"/>
                                        </a>
                                    </div>
                                </div>
                                <div className="rating-box-wrap">
                                    <div className="d-flex">
                                        <a href="https://www.trustpilot.com/review/creativeproxies.com" target="_blank"> <img src="/static/images/stars.svg" alt="Stars"
                                             className="img-fluid stars-img"/>
                                        <h5>
                                            Rated 4.7 on <img src="/static/images/trustpilot.svg" alt="trustpilot"
                                                              className="img-fluid"/>
                                        </h5></a>
                                    </div>
                                </div>
                                <div className="hero-border">
                                    <hr/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                <div className="hero-icon-box">
                                    <div className="media">
                                        <img src="/static/images/hero-icon-01.svg" alt="hero-icon"
                                             className="img-fluid"/>
                                        <div className="media-body">
                                            <h6>
                                                Fast and <br/>Reliable
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                <div className="hero-icon-box" style={{background: "#EEFEF0"}}>
                                    <div className="media">
                                        <img src="/static/images/hero-icon-02.svg" alt="hero-icon"
                                             className="img-fluid"/>
                                        <div className="media-body">
                                            <h6>
                                                Anonymous and <br/>Secure
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                <div className="hero-icon-box" style={{background: "#EFFAF6"}}>
                                    <div className="media">
                                        <img src="/static/images/hero-icon-03.svg" alt="hero-icon"
                                             className="img-fluid"/>
                                        <div className="media-body">
                                            <h6>
                                                Affordable
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                <div className="hero-icon-box" style={{background: "#F0F7FF"}}>
                                    <div className="media">
                                        <img src="/static/images/hero-icon-04.svg" alt="hero-icon"
                                             className="img-fluid"/>
                                        <div className="media-body">
                                            <h6>
                                                IP Authed
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* hero section end */}
                {/* proxy provider section @S */}
                <section className="proxy-provider-sec pa-y4">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="common-heading light-heading">
                                    <h2>Why we are the Best Proxy Providers</h2>
                                    <p>
                                        This is one of the best features we are proud of.
                                        <br/>There is many more reasons to love Creative Proxies but we will let you
                                        find them out yourself
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {/* feature item @S */}
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                <div className="proxy-box-wrap">
                                    <img src="/static/images/feat-08.svg" alt="Feat Icon" className="img-fluid"/>
                                    <h5>50Gbps Network</h5>
                                    <p>We have our own network with up to 50Gbps of dedicated bandwidth which we
                                        constantly upgrade to ensure the fastest speeds.</p>
                                </div>
                            </div>
                            {/* feature item @E */}
                            {/* feature item @S */}
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                <div className="proxy-box-wrap">
                                    <img src="/static/images/feat-02.svg" alt="Feat Icon" className="img-fluid"/>
                                    <h5>Rotating Proxies</h5>
                                    <p>Our proxies rotate every request to ensure they are clean. We also have Static
                                        proxies if you want sticky ips.</p>
                                </div>
                            </div>
                            {/* feature item @E */}
                            {/* feature item @S */}
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                <div className="proxy-box-wrap">
                                    <img src="/static/images/feat-03.svg" alt="Feat Icon" className="img-fluid"/>
                                    <h5>99.9% Uptime</h5>
                                    <p>Our network is designed meticulously to ensure the best uptime at the best
                                        prices.</p>
                                </div>
                            </div>
                            {/* feature item @E */}
                            {/* feature item @S */}
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                <div className="proxy-box-wrap">
                                    <img src="/static/images/feat-04.svg" alt="Feat Icon" className="img-fluid"/>
                                    <h5>Best Customer Service</h5>
                                    <p>While our customer support is not huge, we have hired the best people who can
                                        assist you without wasting your precious time!</p>
                                </div>
                            </div>
                            {/* feature item @E */}
                            {/* feature item @S */}
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                <div className="proxy-box-wrap">
                                    <img src="/static/images/feat-06.svg" alt="Feat Icon" className="img-fluid"/>
                                    <h5>Cleanest Pool</h5>
                                    <p>Our pool is clean and abuse free to ensure that you will be able to use on any
                                        target without getting banned.</p>
                                </div>
                            </div>
                            {/* feature item @E */}
                            {/* feature item @S */}
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                <div className="proxy-box-wrap">
                                    <img src="/static/images/feat-05.svg" alt="Feat Icon" className="img-fluid"/>
                                    <h5>Unlimited Bandwidth</h5>
                                    <p>We do not have a limit on how much bandwidth our customers can use. Our average
                                        customer uses 150Mbps 24/7 but there is really no limit!</p>
                                </div>
                            </div>
                            {/* feature item @E */}
                            {/* feature item @S */}
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                <div className="proxy-box-wrap">
                                    <img src="/static/images/feat-01.svg" alt="Feat Icon" className="img-fluid"/>
                                    <h5>Loved by everyone</h5>
                                    <p>Our customers are extremely satisfied with our service. We believe you will
                                        too!</p>
                                </div>
                            </div>
                            {/* feature item @E */}
                            {/* feature item @S */}
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                <div className="proxy-box-wrap">
                                    <img src="/static/images/feat-07.svg" alt="Feat Icon" className="img-fluid"/>
                                    <h5>>1000 Locations</h5>
                                    <p>We have over 1000 Locations over all our proxies including many Tier 1, Tier 2
                                        and Tier 3 Countries</p>
                                </div>
                            </div>
                            {/* feature item @E */}
                        </div>
                    </div>
                </section>
                {/* proxy provider section @E */}
                {/* pricing section @S */}
                <section className="pricing-section pa-y4">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="common-heading">
                                    <h2>
                                        You choose, we <span className="blu">Deliver!</span>
                                    </h2>
                                    <p>
                                        We have many options for all use cases. <br/> Choose your plan according to your needs!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-5">
                                <div className="pricing-box-wrap">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="price-header">
                                                <img src="/static/images/v4.svg" alt="a" className="img-fluid"/>
                                                <h3>IPv4 Proxies</h3>
                                                <h5>Static/Rotating</h5>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="price-header-premium">
                                                <p>FROM</p>
                                                <p>
                                                    <span>$4.75/</span> day
                                                </p>
                                                <p>OR</p>
                                                <p>
                                                    <span>$116.75</span> /mo
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pricing-botom-txt">
                                        <h6>Plan Includes :</h6>
                                        <ul>
                                            <li>
                                                <img src="/static/images/check-icon.svg" alt="a"
                                                     className="img-fluid"/> 100 Threads
                                            </li>
                                            <li>
                                                <img src="/static/images/check-icon.svg" alt="a"
                                                     className="img-fluid"/> Dedicated IP Pool
                                            </li>
                                            <li>
                                                <img src="/static/images/check-icon.svg" alt="a"
                                                     className="img-fluid"/> Residential Proxy
                                            </li>
                                            <li>
                                                <img src="/static/images/check-icon.svg" alt="a"
                                                     className="img-fluid"/> Unlimited Bandwidth
                                            </li>
                                            <li>
                                                <img src="/static/images/check-icon.svg" alt="a"
                                                     className="img-fluid"/> Worldwide Locations
                                            </li>
                                            <li>
                                                <img src="/static/images/check-icon.svg" alt="a"
                                                     className="img-fluid"/> 40 GBps
                                            </li>
                                        </ul>
                                        <a href="/pricing/ipv4"><button type="button" className="btn btn-plan">
                                            View Pricing <img src="/static/images/arrow-right.svg" alt="a"
                                                              className="img-fluid"/>
                                        </button></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <div className="pricing-box-wrap best-seller-wrap">
                                    <span className="best-seller-tag">BEST SELLER</span>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="price-header">
                                                <img src="/static/images/v6.svg" alt="a" className="img-fluid"/>
                                                <h3>IPv6 Proxies</h3>
                                                <h5>Static/Rotating</h5>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="price-header-premium">
                                                <p>FROM</p>
                                                <p>
                                                    <span>$4.75/</span> day
                                                </p>
                                                <p>OR</p>
                                                <p>
                                                    <span>$116.75</span> /mo
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pricing-botom-txt">
                                        <h6>Plan Includes :</h6>
                                        <ul>
                                            <li>
                                                <img src="/static/images/check-icon.svg" alt="a"
                                                     className="img-fluid"/> 100 Threads
                                            </li>
                                            <li>
                                                <img src="/static/images/check-icon.svg" alt="a"
                                                     className="img-fluid"/> Dedicated IP Pool
                                            </li>
                                            <li>
                                                <img src="/static/images/check-icon.svg" alt="a"
                                                     className="img-fluid"/> Residential Proxy
                                            </li>
                                            <li>
                                                <img src="/static/images/check-icon.svg" alt="a"
                                                     className="img-fluid"/> Unlimited Bandwidth
                                            </li>
                                            <li>
                                                <img src="/static/images/check-icon.svg" alt="a"
                                                     className="img-fluid"/> Worldwide Locations
                                            </li>
                                            <li>
                                                <img src="/static/images/check-icon.svg" alt="a"
                                                     className="img-fluid"/> Dedicated Subnets
                                            </li>
                                            <li>
                                                <img src="/static/images/check-icon.svg" alt="a"
                                                     className="img-fluid"/> 1 Octilion IPS
                                            </li>
                                            <li>
                                                <img src="/static/images/check-icon.svg" alt="a"
                                                     className="img-fluid"/> Septiltion per Location
                                            </li>
                                        </ul>
                                        <a href="/pricing/ipv6"><button type="button" className="btn btn-plan">
                                            View Pricing <img src="/static/images/arrow-right.svg" alt="a"
                                                              className="img-fluid"/>
                                        </button></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-10">
                                <div className="become-reseller-txt-box">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="reseller-title">
                                                <img src="/static/images/reseller-cap-icon.svg" alt="a"
                                                     className="img-fluid"/>
                                                <h5>Become a Reseller</h5>
                                                <p>200+ Happy Resellers</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="reseller-title">
                                                <p>Plan Includes :</p>
                                                <ul>
                                                    <li>
                                                        <img src="/static/images/check-icon.svg" alt="a"
                                                             className="img-fluid"/> Whitelabel IP Pool
                                                    </li>
                                                    <li>
                                                        <img src="/static/images/check-icon.svg" alt="a"
                                                             className="img-fluid"/> Whitelabel Subnets
                                                    </li>
                                                    <li>
                                                        <img src="/static/images/check-icon.svg" alt="a"
                                                             className="img-fluid"/> Residential Proxy
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                            <div className="reseller-title">
                                                <p>&nbsp;</p>
                                                <ul>
                                                    <li>
                                                        <img src="/static/images/check-icon.svg" alt="a"
                                                             className="img-fluid"/> Custom Bandwidth
                                                    </li>
                                                    <li>
                                                        <img src="/static/images/check-icon.svg" alt="a"
                                                             className="img-fluid"/> Priority Support
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* pricing section @E */}
                {/* customer feedback @S */}
                <section className="customer-feedback pa-y4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="common-heading">
                                    <h2>
                                        Customers <span className="grn">Love</span> our Proxies
                                    </h2>
                                    <p>
                                        We have a satisfaction rate of over 98% <br/> You will not be disappointed!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-4">
                                <div className="feedback-wrap">
                                    <div className="d-flex">
                                        <ul>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fa fa-star-half"/>
                                            </li>
                                        </ul>
                                    </div>
                                    <p>
                                        "good priced proxy provider, can recommend"
                                    </p>
                                    <div className="media">
                                        <img src="/static/images/fraft.webp" alt="Avatar" style={{"maxWidth": "20%"}}
                                             className="img-fluid"/>
                                        <div className="media-body">
                                            <h5>Friedrich Kräft</h5>
                                            <h6>CEO, 3xK Tech GmbH</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4">
                                <div className="feedback-wrap">
                                    <div className="d-flex">
                                        <ul>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                        </ul>
                                    </div>
                                    <p>
                                        “I have been utilizing CreativeProxies as my exclusive proxy service provider
                                        for an extended period. Their reliable performance and prompt customer support
                                        have consistently met my needs without any problems.”
                                    </p>
                                    <div className="media">
                                        <img src="/static/images/paulo.jpg" alt="Avatar"
                                             style={{"maxWidth": "20%", borderRadius: "50px"}} className="img-fluid"/>
                                        <div className="media-body">
                                            <h5>Paulo</h5>
                                            <h6>Customer</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4">
                                <div className="feedback-wrap">
                                    <div className="d-flex">
                                        <ul>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                        </ul>
                                    </div>
                                    <p>
                                        “Thanks to CreativeProxies and their staff, always have given me best solutions
                                        for my business”
                                    </p>
                                    <div className="media">
                                        <img src="/static/images/singh.jpg" alt="Avatar"
                                             style={{"maxWidth": "20%", borderRadius: "50px"}} className="img-fluid"/>
                                        <div className="media-body">
                                            <h5>Suresh Singh</h5>
                                            <h6>Social Media Specialist, OverView Marketing Co</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* customer feedback @E */}
                {/* blog section @S */}
                {false &&
                    <section className="blog-section pa-y4">
                        <div className="container custom-bg">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="common-heading">
                                        <h2>
                                            Read our <span className="blu">Exclusive</span> Blog
                                        </h2>
                                        <a href="/blog">
                                            Read All Blogs <img src="/static/images/arrow-right-blu.svg"
                                                                alt="Arrow Right" className="img-fluid"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-6 col-lg-4">
                                    <div className="blog-box-wrap">
                                        <img src="/static/images/blog-img.svg" alt="Blog" className="img-fluid"/>
                                        <div className="blog-txt">
                                            <h5>Blog Title appear here</h5>
                                            <p>Category name here</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                    <div className="blog-box-wrap">
                                        <img src="/static/images/blog-img.svg" alt="Blog" className="img-fluid"/>
                                        <div className="blog-txt">
                                            <h5>Blog Title appear here</h5>
                                            <p>Category name here</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                    <div className="blog-box-wrap">
                                        <img src="/static/images/blog-img.svg" alt="Blog" className="img-fluid"/>
                                        <div className="blog-txt">
                                            <h5>Blog Title appear here</h5>
                                            <p>Category name here</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                    <div className="blog-box-wrap">
                                        <img src="/static/images/blog-img.svg" alt="Blog" className="img-fluid"/>
                                        <div className="blog-txt">
                                            <h5>Blog Title appear here</h5>
                                            <p>Category name here</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                    <div className="blog-box-wrap">
                                        <img src="/static/images/blog-img.svg" alt="Blog" className="img-fluid"/>
                                        <div className="blog-txt">
                                            <h5>Blog Title appear here</h5>
                                            <p>Category name here</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                    <div className="blog-box-wrap">
                                        <img src="/static/images/blog-img.svg" alt="Blog" className="img-fluid"/>
                                        <div className="blog-txt">
                                            <h5>Blog Title appear here</h5>
                                            <p>Category name here</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
                {/* blog section @E */}
            </div>
            <Footer/>
        </>
    );
}

export default Index;
