import React from "react";

function Header() {
    return (
        <nav className="navbar header-section navbar-expand-lg navbar-light">
            <div className="container bordr-custm">
                <a className="navbar-brand" href="/">
                    <img src="/static/images/logo.svg" alt="Logo" className="img-fluid"/>
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarText"
                    aria-controls="navbarText"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <i className="fa fa-bars"/>
                </button>
                <div className="collapse navbar-collapse" id="navbarText">
                    <ul className="navbar-nav me-auto">
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                Pricing <i className="fa fa-angle-down"/>
                            </a>
                            {/* submenu @S */}
                            <ul className="navbar-submenu">
                                <li>
                                    <a href="/pricing/ipv4">
                                        <span>IPv4</span>
                                        <span>Static and Rotating</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/pricing/ipv6" className="grn">
                                        <span>IPv6</span>
                                        <span>Static and Rotating</span>
                                    </a>
                                </li>
                            </ul>
                            {/* submenu @E */}
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                Explore Tools <i className="fa fa-angle-down"/>
                            </a>
                            {/* submenu @S */}
                            <ul className="navbar-submenu">
                                <li>
                                    <a href="/location-checker">
                                        <span>IP Location Checker</span>
                                        <span>Check the location of your proxies</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/compatibility-checker" className="grn">
                                        <span>IPv6 Compatibility</span>
                                        <span>Check IPv6 Compatibility </span>
                                    </a>
                                </li>
                            </ul>
                            {/* submenu @E */}
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                Resources <i className="fa fa-angle-down"/>
                            </a>
                            {/* submenu @S */}
                            <ul className="navbar-submenu">
                                <li>
                                    <a href="/network-information">
                                        <span>Network Information</span>
                                        <span>View CreativeProxies Network Info</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/imprint" className="orange">
                                        <span>Company Imprint</span>
                                        <span>View CreativeProxies Imprint</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/branding" className="grn">
                                        <span>Branding</span>
                                        <span>Explore our Media Assets</span>
                                    </a>
                                </li>
                            </ul>
                            {/* submenu @E */}
                        </li>
                        {false && <>
                        <li className="nav-item">
                            <a className="nav-link" href="/faq">
                                FAQs
                            </a>
                        </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/blog">
                                    Blogs
                                </a>
                            </li></>}
                    </ul>
                    <form className="d-flex">
                        <a href="https://discord.gg/creativeproxies">
                            <img src="/static/images/discord-icon.svg" alt="Discord" className="img-fluid"/> Join
                            Discord
                        </a>
                        <a href="https://app.creativeproxies.com/login">Log In</a>
                        <a href="https://app.creativeproxies.com/register">Get Started</a>
                    </form>
                </div>
            </div>
        </nav>
    );
}

export default Header;
