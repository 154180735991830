import React from "react";

export default function Page404() {
    return (
        <>
            <section className="tos-section pa-y4">
                <div className="container">
                    {/* common heading start */}
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common-heading">
                                <img src="/static/images/error-img.svg" alt={404} className="img-fluid"/>
                                <h2>Page Not Found</h2>
                                <p>The page you are looking for doesn't exist or has been moved</p>
                                <a href="#" className="error-bttn">
                                    <img src="/static/images/arrow-left.svg" alt="<-" className="img-fluid"/> Go Back to
                                    Homepage
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* common heading end */}
                </div>
            </section>
        </>
    );
}
