import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

function Blogs() {
    return (
        <>
            {" "}
            <Header/>
            {/* blogs section start */}
            <section className="blogss-section pa-y4 pt-0">
                <div className="container">
                    {/* common heading start */}
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="common-heading text-start mb-0">
                                <h2>Read Our Blogs</h2>
                            </div>
                            <div className="tos-heading">
                                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link active"
                                            id="pills-home-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-home"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-home"
                                            aria-selected="true"
                                        >
                                            Recently Posted
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="pills-profile-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-profile"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-profile"
                                            aria-selected="false"
                                        >
                                            Category 1
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="pills-contact-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-contact"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-contact"
                                            aria-selected="false"
                                        >
                                            Category 2
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="pills-contactOne-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-contactOne"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-contactOne"
                                            aria-selected="false"
                                        >
                                            Category 2
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="pills-contactTwo-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-contactTwo"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-contactTwo"
                                            aria-selected="false"
                                        >
                                            Category 3
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="pills-contactThree-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-contactThree"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-contactThree"
                                            aria-selected="false"
                                        >
                                            Category 4
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="pills-contactFour-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-contactFour"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-contactFour"
                                            aria-selected="false"
                                        >
                                            Category 5
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="pills-contactFive-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-contactFive"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-contactFive"
                                            aria-selected="false"
                                        >
                                            Category 6
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* common heading end */}
                    <div className="row mt-4 justify-content-center">
                        <div className="col-lg-12">
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                                     aria-labelledby="pills-home-tab" tabIndex={0}>
                                    .
                                    <div className="row">
                                        <div className="col-md-6 col-lg-5">
                                            <div className="blogs-main-img">
                                                <img src="/static/images/blog-main.svg" alt="a" className="img-fluid"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-7">
                                            <div className="blogs-main-txt">
                                                <h6>March 2, 2023 . 4 Min Read</h6>
                                                <h3>Blog Title appear here</h3>
                                                <p>Category name here</p>
                                                <p>
                                                    Nulla hendrerit ipsum eget dolor gravida, eget accumsan orci auctor.
                                                    Pellentesque habitant morbi tristique senectus et netus
                                                    et malesuada fames ac turpis egestas. Nam ex nibh, feugiat quis
                                                    massa eget, dapibus fermentum augue. Orci varius natoque
                                                    penatibus et magnis dis parturient montes
                                                </p>
                                                <a href="#">
                                                    Read Full Blog <img src="/static/images/arrow-up.svg" alt="Arrow"
                                                                        className="img-fluid"/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="blogs-head">
                                            <h5>Read More Blogs</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-profile" role="tabpanel"
                                     aria-labelledby="pills-profile-tab" tabIndex={0}>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-5">
                                            <div className="blogs-main-img">
                                                <img src="/static/images/blog-main.svg" alt="a" className="img-fluid"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-7">
                                            <div className="blogs-main-txt">
                                                <h6>March 2, 2023 . 4 Min Read</h6>
                                                <h3>Blog Title appear here</h3>
                                                <p>Category name here</p>
                                                <p>
                                                    Nulla hendrerit ipsum eget dolor gravida, eget accumsan orci auctor.
                                                    Pellentesque habitant morbi tristique senectus et netus
                                                    et malesuada fames ac turpis egestas. Nam ex nibh, feugiat quis
                                                    massa eget, dapibus fermentum augue. Orci varius natoque
                                                    penatibus et magnis dis parturient montes
                                                </p>
                                                <a href="#">
                                                    Read Full Blog <img src="/static/images/arrow-up.svg" alt="Arrow"
                                                                        className="img-fluid"/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="blogs-head">
                                            <h5>Read More Blogs</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-contact" role="tabpanel"
                                     aria-labelledby="pills-contact-tab" tabIndex={0}>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-5">
                                            <div className="blogs-main-img">
                                                <img src="/static/images/blog-main.svg" alt="a" className="img-fluid"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-7">
                                            <div className="blogs-main-txt">
                                                <h6>March 2, 2023 . 4 Min Read</h6>
                                                <h3>Blog Title appear here</h3>
                                                <p>Category name here</p>
                                                <p>
                                                    Nulla hendrerit ipsum eget dolor gravida, eget accumsan orci auctor.
                                                    Pellentesque habitant morbi tristique senectus et netus
                                                    et malesuada fames ac turpis egestas. Nam ex nibh, feugiat quis
                                                    massa eget, dapibus fermentum augue. Orci varius natoque
                                                    penatibus et magnis dis parturient montes
                                                </p>
                                                <a href="#">
                                                    Read Full Blog <img src="/static/images/arrow-up.svg" alt="Arrow"
                                                                        className="img-fluid"/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="blogs-head">
                                            <h5>Read More Blogs</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-contactOne" role="tabpanel"
                                     aria-labelledby="pills-contactOne-tab" tabIndex={0}>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-5">
                                            <div className="blogs-main-img">
                                                <img src="/static/images/blog-main.svg" alt="a" className="img-fluid"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-7">
                                            <div className="blogs-main-txt">
                                                <h6>March 2, 2023 . 4 Min Read</h6>
                                                <h3>Blog Title appear here</h3>
                                                <p>Category name here</p>
                                                <p>
                                                    Nulla hendrerit ipsum eget dolor gravida, eget accumsan orci auctor.
                                                    Pellentesque habitant morbi tristique senectus et netus
                                                    et malesuada fames ac turpis egestas. Nam ex nibh, feugiat quis
                                                    massa eget, dapibus fermentum augue. Orci varius natoque
                                                    penatibus et magnis dis parturient montes
                                                </p>
                                                <a href="#">
                                                    Read Full Blog <img src="/static/images/arrow-up.svg" alt="Arrow"
                                                                        className="img-fluid"/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="blogs-head">
                                            <h5>Read More Blogs</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-contactTwo" role="tabpanel"
                                     aria-labelledby="pills-contactTwo-tab" tabIndex={0}>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-5">
                                            <div className="blogs-main-img">
                                                <img src="/static/images/blog-main.svg" alt="a" className="img-fluid"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-7">
                                            <div className="blogs-main-txt">
                                                <h6>March 2, 2023 . 4 Min Read</h6>
                                                <h3>Blog Title appear here</h3>
                                                <p>Category name here</p>
                                                <p>
                                                    Nulla hendrerit ipsum eget dolor gravida, eget accumsan orci auctor.
                                                    Pellentesque habitant morbi tristique senectus et netus
                                                    et malesuada fames ac turpis egestas. Nam ex nibh, feugiat quis
                                                    massa eget, dapibus fermentum augue. Orci varius natoque
                                                    penatibus et magnis dis parturient montes
                                                </p>
                                                <a href="#">
                                                    Read Full Blog <img src="/static/images/arrow-up.svg" alt="Arrow"
                                                                        className="img-fluid"/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="blogs-head">
                                            <h5>Read More Blogs</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-contactThree" role="tabpanel"
                                     aria-labelledby="pills-contactThree-tab" tabIndex={0}>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-5">
                                            <div className="blogs-main-img">
                                                <img src="/static/images/blog-main.svg" alt="a" className="img-fluid"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-7">
                                            <div className="blogs-main-txt">
                                                <h6>March 2, 2023 . 4 Min Read</h6>
                                                <h3>Blog Title appear here</h3>
                                                <p>Category name here</p>
                                                <p>
                                                    Nulla hendrerit ipsum eget dolor gravida, eget accumsan orci auctor.
                                                    Pellentesque habitant morbi tristique senectus et netus
                                                    et malesuada fames ac turpis egestas. Nam ex nibh, feugiat quis
                                                    massa eget, dapibus fermentum augue. Orci varius natoque
                                                    penatibus et magnis dis parturient montes
                                                </p>
                                                <a href="#">
                                                    Read Full Blog <img src="/static/images/arrow-up.svg" alt="Arrow"
                                                                        className="img-fluid"/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="blogs-head">
                                            <h5>Read More Blogs</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-contactFour" role="tabpanel"
                                     aria-labelledby="pills-contactFour-tab" tabIndex={0}>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-5">
                                            <div className="blogs-main-img">
                                                <img src="/static/images/blog-main.svg" alt="a" className="img-fluid"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-7">
                                            <div className="blogs-main-txt">
                                                <h6>March 2, 2023 . 4 Min Read</h6>
                                                <h3>Blog Title appear here</h3>
                                                <p>Category name here</p>
                                                <p>
                                                    Nulla hendrerit ipsum eget dolor gravida, eget accumsan orci auctor.
                                                    Pellentesque habitant morbi tristique senectus et netus
                                                    et malesuada fames ac turpis egestas. Nam ex nibh, feugiat quis
                                                    massa eget, dapibus fermentum augue. Orci varius natoque
                                                    penatibus et magnis dis parturient montes
                                                </p>
                                                <a href="#">
                                                    Read Full Blog <img src="/static/images/arrow-up.svg" alt="Arrow"
                                                                        className="img-fluid"/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="blogs-head">
                                            <h5>Read More Blogs</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-contactFive" role="tabpanel"
                                     aria-labelledby="pills-contactFive-tab" tabIndex={0}>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-5">
                                            <div className="blogs-main-img">
                                                <img src="/static/images/blog-main.svg" alt="a" className="img-fluid"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-7">
                                            <div className="blogs-main-txt">
                                                <h6>March 2, 2023 . 4 Min Read</h6>
                                                <h3>Blog Title appear here</h3>
                                                <p>Category name here</p>
                                                <p>
                                                    Nulla hendrerit ipsum eget dolor gravida, eget accumsan orci auctor.
                                                    Pellentesque habitant morbi tristique senectus et netus
                                                    et malesuada fames ac turpis egestas. Nam ex nibh, feugiat quis
                                                    massa eget, dapibus fermentum augue. Orci varius natoque
                                                    penatibus et magnis dis parturient montes
                                                </p>
                                                <a href="#">
                                                    Read Full Blog <img src="/static/images/arrow-up.svg" alt="Arrow"
                                                                        className="img-fluid"/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="blogs-head">
                                            <h5>Read More Blogs</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-contactSix" role="tabpanel"
                                     aria-labelledby="pills-contactSix-tab" tabIndex={0}>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-5">
                                            <div className="blogs-main-img">
                                                <img src="/static/images/blog-main.svg" alt="a" className="img-fluid"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-7">
                                            <div className="blogs-main-txt">
                                                <h6>March 2, 2023 . 4 Min Read</h6>
                                                <h3>Blog Title appear here</h3>
                                                <p>Category name here</p>
                                                <p>
                                                    Nulla hendrerit ipsum eget dolor gravida, eget accumsan orci auctor.
                                                    Pellentesque habitant morbi tristique senectus et netus
                                                    et malesuada fames ac turpis egestas. Nam ex nibh, feugiat quis
                                                    massa eget, dapibus fermentum augue. Orci varius natoque
                                                    penatibus et magnis dis parturient montes
                                                </p>
                                                <a href="#">
                                                    Read Full Blog <img src="/static/images/arrow-up.svg" alt="Arrow"
                                                                        className="img-fluid"/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="blogs-head">
                                            <h5>Read More Blogs</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <div className="blog-box-wrap">
                                                <img src="/static/images/blog-img.svg" alt="Blog"
                                                     className="img-fluid"/>
                                                <div className="blog-txt">
                                                    <h5>Blog Title appear here</h5>
                                                    <p>Category name here</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* blogs section end */}
            <Footer/>
        </>
    );
}

export default Blogs;
