import React, {useState} from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import {useMutation} from "react-query";
import {Box, CardContent} from "@mui/material";
import axios from "axios";
import {DataGridPro, GridToolbar} from "@mui/x-data-grid-pro";

function LocationChecker() {
    const columns = [
        {
            field: "ip", headerName: "IP", flex: 1
        },
        {
            field: "city",
            headerName: "City",
            disableColumnMenu: true,
            flex: 1,
            renderCell: ({value}) => (<p color="textSecondary" variant={"body2"}>{value}</p>)
        },
        {
            field: "country",
            headerName: "Country",
            disableColumnMenu: true,
            flex: 1,
            renderCell: ({value}) => (<p color="textSecondary" variant={"body2"}>{value}</p>)
        },
    ]
    const [pageSize, setPageSize] = React.useState(5);
    const [IPs, setIPs] = useState("")
    const [data, setData] = useState()
    const checkIPsMutation = useMutation(
        list => axios.post(
            "/landing/check/locations",
            {ips: list}
        ),
        {
            onSuccess: (data) => {
                setData(data.data)
            }
        }
    )

    function handleSubmit(event) {
        checkIPsMutation.mutate(IPs.split('\n'))
        event.preventDefault()
    }

    return (
        <>
            {" "}
            <Header/>
            <section className="location-checker-section pa-y4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common-heading">
                                <p className="m-0 mb-1">IP LOCATION CHECKER</p>
                                <h2>
                                    Check Locations of any <br/>
                                    IP <span className="blu">Free</span> of Cost
                                </h2>
                                <p>
                                    To Check location, enter the ip address you <br/> want to check (1 IP Per
                                    line)
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className="location-checker-tool-wrap">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <textarea className="form-control" value={IPs} onChange={(event) => {
                                            setIPs(event.target.value)
                                        }} defaultValue={""}/>
                                    </div>
                                    <div className="d-flex">
                                        <button className="btn btn-submit" type="submit">
                                            Check Location
                                        </button>
                                        <p>
                                            By using our tool, you agree our <br/>
                                            Terms of Service and Privacy Policy
                                        </p>
                                    </div>
                                </form>
                            </div>
                            <div className="netowork-info-table-box location-check-table">
                                <h5>Your Results</h5>
                                {data ?
                                    <CardContent sx={{minHeight: 500}}>
                                        <Box sx={{height: 320, width: '100%'}}>
                                            <DataGridPro
                                                columns={columns}
                                                rows={data || []}
                                                checkboxSelection
                                                rowHeight={38}
                                                components={{Toolbar: GridToolbar}}
                                                pagination
                                                pageSize={pageSize}
                                                getRowId={(row) => row.ip}
                                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                            /></Box></CardContent>
                                    :
                                    <p>Results will show here</p>}
                            </div>
                            <div className="table-more">
                                <p>
                                    Check out our IPv6 Compatibility Checker{" "}
                                    <a href="/compatibility-checker">
                                        View Tool <img src="/static/images/arrow-right-blu.svg" alt="a"
                                                       className="img-fluid"/>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
}

export default LocationChecker;
