import React, {useState} from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import {useMutation} from "react-query";
import axios from "axios";
import {DataGridPro} from "@mui/x-data-grid-pro";

function CompChecker() {
    const columnsARecords = [
        {
            field: "domain",
            headerName: "A Web Server Records",
            disableColumnMenu: true,
            flex: 1,
            renderCell: ({value}) => (<p color="textSecondary" variant={"body2"}>{value}</p>)
        },
        {
            field: "ip", headerName: "IP", flex: 1,
            disableColumnMenu: true,
            renderCell: ({value}) => (<p color="textSecondary" variant={"body2"}>{value}</p>)
        },
    ]
    const columnsIPv6 = [
        {
            field: "ipv6",
            headerName: "IPv6 Compatibility Info",
            disableColumnMenu: true,
            flex: 1,
            renderCell: ({value}) => (<p>{ipv6Compatible ? "IPv6 Fully Compatible" : "Not IPv6 Compatible"}</p>)
        },
        {
            field: "boolean", headerName: "Is Compatible", flex: 1,
            disableColumnMenu: true,
            renderCell: ({value}) => (ipv6Compatible ? <p style={{color: "#16D113"}}>YES</p> :
                <p style={{color: "RED"}}>NO</p>)
        },
    ]
    const columnsAAAARecords = [
        {
            field: "domain",
            headerName: "AAAA Web Server Records",
            disableColumnMenu: true,
            flex: 1,
            renderCell: ({value}) => (<p color="textSecondary" variant={"body2"}>{value}</p>)
        },
        {
            field: "ip", headerName: "IP", flex: 1,
            disableColumnMenu: true,
            renderCell: ({value}) => (<p color="textSecondary" variant={"body2"}>{value}</p>)
        },
    ]
    const [pageSize, setPageSize] = React.useState(5);
    const [domain, setDomain] = useState("")
    const [data, setData] = useState()
    const [ipv6Compatible, setIpv6Compatible] = useState(false)
    const [aRecords, setARecords] = useState(false)
    const [aaaaRecords, setAAAARecords] = useState(false)


    const getDnsMutation = useMutation(
        domain => axios.post(
            "/landing/check/dns",
            {website: domain}
        ),
        {
            onSuccess: (resp) => {
                const data = resp.data
                setIpv6Compatible(data.ipv6)
                setARecords(data.aRecords)
                setAAAARecords(data.aaaaRecords)
            }
        }
    )

    function handleSubmit(event) {
        getDnsMutation.mutate(domain)
        event.preventDefault()
    }

    return (
        <>
            {" "}
            <Header/>
            <section className="location-checker-section pa-y4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common-heading">
                                <p className="m-0 mb-1">IPv6 COMPATIBILITY CHECKER</p>
                                <h2>
                                    Check IPv6 Compatibility of <br/>
                                    any domain <span className="blu">Free</span> of Cost
                                </h2>
                                <p>
                                    Just enter a domain and this tool instantly checks the <br/> IPv6 Compatibility
                                    against a given domain.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className="location-checker-tool-wrap">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group form-group-input">
                                        <input
                                            type="text"
                                            placeholder="Enter a valid domain"
                                            className="form-control"
                                            value={domain} onChange={(event) => {
                                            setDomain(event.target.value)
                                        }}
                                            style={{minHeight: "3rem", paddingLeft: "3rem"}}
                                        />
                                        <img src="/static/images/link-icon.svg" alt="Link" className="img-fluid"/>
                                    </div>
                                    <div className="d-flex">
                                        <button className="btn btn-submit" type="submit">
                                            Check Compatibility
                                        </button>
                                        <p>
                                            By using our tool, you agree our <br/>
                                            Terms of Service and Privacy Policy
                                        </p>
                                    </div>
                                </form>
                            </div>
                            <div className="netowork-info-table-box location-check-table">
                                <h5>Your Results</h5>
                                {aRecords || aaaaRecords ? <>
                                        <div style={{width: "110%"}}>
                                            <DataGridPro
                                                columns={columnsIPv6}
                                                rows={[{ipv6: ipv6Compatible}]}
                                                autoHeight={true}
                                                autoWidth={true}
                                                rowsPerPageOptions={[5]}
                                                pageSize={5}
                                                disableSelectionOnClick
                                                density="compact"
                                                hideFooterPagination={true}
                                                hideFooter={true}
                                                showCellRightBorder={true}
                                                showColumnRightBorder={true}
                                                hideScrollbar={true}
                                                getRowId={(row) => row.ipv6}
                                            /></div>
                                        {aRecords &&
                                            <div style={{width: "110%"}}>
                                                <DataGridPro
                                                    columns={columnsARecords}
                                                    rows={aRecords || []}
                                                    autoHeight={true}
                                                    autoWidth={true}
                                                    rowsPerPageOptions={[5]}
                                                    pageSize={5}
                                                    disableSelectionOnClick
                                                    density="compact"
                                                    hideFooterPagination={true}
                                                    hideFooter={true}
                                                    showCellRightBorder={true}
                                                    showColumnRightBorder={true}
                                                    hideScrollbar={true}
                                                    getRowId={(row) => row.ip}
                                                /></div>}
                                        {aaaaRecords &&
                                            <div style={{width: "110%"}}>
                                                <DataGridPro
                                                    columns={columnsAAAARecords}
                                                    rows={aaaaRecords || []}
                                                    autoHeight={true}
                                                    autoWidth={true}
                                                    rowsPerPageOptions={[5]}
                                                    pageSize={5}
                                                    disableSelectionOnClick
                                                    density="compact"
                                                    hideFooterPagination={true}
                                                    hideFooter={true}
                                                    showCellRightBorder={true}
                                                    showColumnRightBorder={true}
                                                    hideScrollbar={true}
                                                    getRowId={(row) => row.ip}
                                                /></div>}</> :
                                    <p>Your records will show here</p>}
                            </div>
                            <div className="table-more">
                                <p>
                                    Check out our IP Location Checker{" "}
                                    <a href="/location-checker">
                                        View Tool <img src="/static/images/arrow-right-blu.svg" alt="a"
                                                       className="img-fluid"/>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
}

export default CompChecker;
