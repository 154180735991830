import React, {useEffect} from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

function Refund() {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return (
        <>
            {" "}
            <Header/>
            <section className="tos-section pa-y4 pt-0">
                <div className="container">
                    {/* common heading start */}
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="common-heading text-start mb-0">
                                <h2>Legal</h2>
                            </div>
                            <div className="tos-heading">
                                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a
                                            className="nav-link"
                                            id="pills-tos-tab"
                                            type="button"
                                            href="/tos"
                                        >
                                            Terms of Service
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a
                                            className="nav-link"
                                            id="pills-privacy-tab"
                                            type="button"
                                            href="/privacy"
                                        >
                                            Privacy Policy
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a
                                            className="nav-link"
                                            id="pills-privacy-tab"
                                            type="button"
                                            href="/cookies"
                                        >
                                            Cookies Policy
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a
                                            className="nav-link active"
                                            id="pills-refund-tab"
                                            type="button"
                                            href="/refund"
                                        >
                                            Refund Policy
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a
                                            className="nav-link"
                                            id="pills-refund-tab"
                                            type="button"
                                            href="/acceptable"
                                        >
                                            Acceptable Use Policy
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* common heading end */}
                    <div className="row mt-4 justify-content-center">
                        <div className="col-lg-10">
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-refund" role="tabpanel"
                                     aria-labelledby="pills-refund-tab" tabIndex={3}>

                                    <div name="termly-embed" data-id="6a72085a-baf3-47c1-81c6-12ac847f7122" data-type="iframe"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
}

export default Refund;
