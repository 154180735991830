import React from "react";
import {Navigate, useRoutes} from "react-router-dom";
import Page404 from "../pages/General/Page404";
import Page500 from "../pages/General/Page500";
import Index from "../pages/Index";
import Blogs from "../pages/Blogs";
import BlogsView from "../pages/BlogsView";
import Branding from "../pages/Branding";
import CompChecker from "../pages/CompChecker";
import CompCheckerError from "../pages/CompCheckerError";
import Faq from "../pages/Faq";
import Imprint from "../pages/Imprint";
import LocationChecker from "../pages/LocationChecker";
import LocationCheckerError from "../pages/LocationCheckerError";
import NetworkInformation from "../pages/NetworkInformation";
import PricingCustom from "../pages/PricingCustom";
import Tos from "../pages/Tos";
import Refund from "../pages/Refund";
import Privacy from "../pages/Privacy";
import Cookies from "../pages/Cookies";
import AcceptableUse from "../pages/AcceptableUse";

export default function Router() {
    return useRoutes([
        {
            path: "",
            element: <Index/>,
        },
        {
            path: "blog",
            element: <Blogs/>,
        },
        {
            path: "blog-view",
            element: <BlogsView/>,
        },
        {
            path: "branding",
            element: <Branding/>,
        },
        {
            path: "compatibility-checker",
            element: <CompChecker/>,
        },
        {
            path: "compatibility-checker-error",
            element: <CompCheckerError/>,
        },
        {
            path: "faq",
            element: <Faq/>,
        },
        {
            path: "imprint",
            element: <Imprint/>,
        },
        {
            path: "location-checker",
            element: <LocationChecker/>,
        },
        {
            path: "location-checker-error",
            element: <LocationCheckerError/>,
        },
        {
            path: "network-information",
            element: <NetworkInformation/>,
        },
        {
            path: "pricing/ipv4",
            element: <PricingCustom version="IPv4"/>,
        },
        {
            path: "pricing/ipv6",
            element: <PricingCustom version="IPv6"/>,
        },
        {
            path: "tos",
            element: <Tos/>,
        },
        {
            path: "refund",
            element: <Refund/>,
        },
        {
            path: "privacy",
            element: <Privacy/>,
        },
        {
            path: "cookies",
            element: <Cookies/>,
        },
        {
            path: "acceptable",
            element: <AcceptableUse/>,
        },

        {path: "404", element: <Page404/>},
        {path: "500", element: <Page500/>},
        {path: "*", element: <Navigate to="/404" replace/>},
    ]);
}
