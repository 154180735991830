import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

function Branding() {
    return (
        <>
            {" "}
            <Header/>
            <section className="branding-section pa-y4 pt-0">
                <div className="container">
                    {/* common heading start */}
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="common-heading text-start mb-0">
                                <h2>Branding</h2>
                                <p>Official CreativeProxies Logo, Colors, and more for use on your site.</p>
                                <hr/>
                            </div>
                        </div>
                    </div>
                    {/* common heading end */}
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="row mt-3">
                                <div className="col-lg-5">
                                    <div className="branding-txt">
                                        <h5>
                                            Creative Proxies <br/>
                                            Brand Guidelines
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="branding-txt">
                                        <p>
                                            When using the assets you see on this page, we ask that you keep just a few
                                            things in mind.
                                            <br/>
                                            Please Do Not :
                                        </p>
                                        <ul>
                                            <li>
                                                <i className="fa fa-circle"/> Modify our logos, icons, or graphics in
                                                any way without our written permission.
                                            </li>
                                            <li>
                                                <i className="fa fa-circle"/> Use our logos or icons as your own
                                                identity, or for your own branding purposes (print or
                                                web-based).
                                            </li>
                                            <li>
                                                <i className="fa fa-circle"/> Use our logos or icons in a way that may
                                                confuse someone that your product or service is made,
                                                run, or endorsed by StudioPress.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-lg-5">
                                    <div className="branding-txt">
                                        <h5>
                                            Creative Proxies <br/>
                                            Logo and Icon
                                        </h5>
                                        <p>
                                            The official CreativeProxies logo (both <br/> logo and icon only).
                                        </p>
                                        <p>
                                            {" "}
                                            All of these files are in PNG format, <br/> with transparent backgrounds
                                            (click <br/> each image to open it in a new tab).
                                        </p>
                                        <p>
                                            {" "}
                                            You can also download our logos in <br/> vector format.
                                        </p>
                                        <a href="#" className="download-bttn">
                                            <img src="/static/images/download-icon.svg" alt="D"
                                                 className="img-fluid"/> Download (1.3MB, .zip)
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="branding-txt">
                                        <div className="branding-imgs">
                                            <a href="#">
                                                <img src="/static/images/creative-01.svg" alt="C"
                                                     className="img-fluid"/>
                                            </a>
                                            <a href="#">
                                                <img src="/static/images/creative-02.svg" alt="C"
                                                     className="img-fluid"/>
                                            </a>
                                            <a href="#">
                                                <img src="/static/images/creative-03.svg" alt="C"
                                                     className="img-fluid"/>
                                            </a>
                                        </div>
                                        <a href="#" className="d-block mt-4">
                                            <img src="/static/images/creative-04.svg" alt="C"
                                                 className="img-fluid d-block w-100"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-lg-5">
                                    <div className="branding-txt">
                                        <h5>Colors and Fonts</h5>
                                        <p>
                                            The hex codes of the official <br/> CreativeProxies brand colors, as
                                            well <br/> as the official typefaces we use across <br/>{" "}
                                            all of our marketing materials.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="branding-txt">
                                        <div className="branding-colors">
                                            <div className="color">
                                                <span style={{background: "#077BFF"}}/>
                                                <p>#077BFF</p>
                                            </div>
                                            <div className="color">
                                                <span style={{background: "#111822"}}/>
                                                <p>#111822</p>
                                            </div>
                                            <div className="color">
                                                <span style={{background: "#16D113"}}/>
                                                <p>#16D113</p>
                                            </div>
                                            <div className="color">
                                                <span style={{background: "#0566D4"}}/>
                                                <p>#0566D4</p>
                                            </div>
                                        </div>
                                        <div className="branding-fonts">
                                            <div className="d-flex">
                                                <h4>Figtree</h4>
                                                <a href="#">
                                                    View on Google Fonts <img src="/static/images/arrow-up.svg" alt="AU"
                                                                              className="img-fluid"/>
                                                </a>
                                            </div>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ac
                                                lacus finibus, pretium orci quis, congue velit. Aliquam
                                                sed dapibus eros.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
}

export default Branding;
