import React, {useEffect, useState} from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

function PricingCustom(props) {
    let svg = "v4"
    if (props.version === 'IPv6') svg = "v6"
    const [rotatingPrices, setRotatingPrices] = useState([])
    const [staticPrices, setStaticPrices] = useState([])
    const [threads, setThreads] = useState(100)
    const [ips, setIPs] = useState(100)
    const [maxThreads, setMaxThreads] = useState(1000)
    const [maxIPs, setMaxIPs] = useState(100)
    useEffect(() => {
        calcRotatingPrices(threads)
        calcStaticPrices(ips)
        setMaxThreads(4000)
        if (props.version === 'IPv6') setMaxIPs(4000)
        else setMaxIPs(1000)
    }, [])

    function calcRotatingPrices(val) {
        let monthly = (Number((val * (8 / 15) + 200 / 3) * 1.08)).toFixed(2)
        let daily = (monthly / 30).toFixed(2)
        let weekly = (monthly / 4).toFixed(2)
        let prices = [daily, weekly, monthly]
        setRotatingPrices(prices)
    }

    function calcStaticPrices(val) {
        let monthly = (Number((val * (8 / 15) + 200 / 3) * 1.08)).toFixed(2)
        if (props.version === 'IPv4') monthly = (Number((val * (32 / 15) + 200 / 3) * 1.08)).toFixed(2)
        let daily = (monthly / 30).toFixed(2)
        let weekly = (monthly / 4).toFixed(2)
        let prices = [daily, weekly, monthly]
        setStaticPrices(prices)
    }

    return (
        <>
            {" "}
            <Header/>
            <div>
                {/* pricing section @S */}
                <section className="pricing-section pa-y4 pt-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="common-heading mb-0">
                                    <img src={`/static/images/${svg}.svg`} alt="Logo" className="img-fluid"/>
                                    <h2>Explore {props.version} Proxies Pricing</h2>
                                    <p>
                                        {props.version === 'IPv6' ? "IPv6 Proxies with Over 1 Octilion Pool Per Customer. Great for compatible websites" : "IPv4 Clean IPs with Extreme Quality. Great for all types of websites"}
                                    </p>
                                </div>
                                <div className="custom-price-tags">
                                    <div className="media">
                                        <p>All Plans Include :</p>
                                        <div className="media-body">
                                            <ul>
                                                <li>
                                                    <img src="/static/images/check-icon.svg" alt="a"
                                                         className="img-fluid"/> 100 Threads
                                                </li>
                                                <li>
                                                    <img src="/static/images/check-icon.svg" alt="a"
                                                         className="img-fluid"/> Dedicated IP Pool
                                                </li>
                                                <li>
                                                    <img src="/static/images/check-icon.svg" alt="a"
                                                         className="img-fluid"/> Residential Proxy
                                                </li>
                                            </ul>
                                            <ul className="mt-2">
                                                <li>
                                                    <img src="/static/images/check-icon.svg" alt="a"
                                                         className="img-fluid"/> Unlimited Bandwidth
                                                </li>
                                                <li>
                                                    <img src="/static/images/check-icon.svg" alt="a"
                                                         className="img-fluid"/> Worldwide Locations
                                                </li>
                                                <li>
                                                    <img src="/static/images/check-icon.svg" alt="a"
                                                         className="img-fluid"/> 50 Gbps
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-11">
                                <div className="price-range-static">
                                    <span>ROTATING</span>
                                    <div className="d-block">
                                        <label htmlFor="customRange1" className="form-label">
                                            NUMBER OF THREADS {threads}
                                        </label>
                                        <input max={maxThreads} onChange={(event) => {
                                            let val = Number(event.target.value)
                                            setThreads(val)
                                            calcRotatingPrices(val)
                                        }} value={threads} step={10} type="range" className="form-range"
                                               id="customRange1"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-4">
                                        <div className="pricing-box-wrap custom-price-box">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="price-header">
                                                        <span>ROTATING</span>
                                                        <h3>1 Day</h3>
                                                        <h5>24 Hours Access</h5>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="price-header-premium">
                                                        <br/>
                                                        <br/>
                                                        <p>FROM</p>
                                                        <p>
                                                            <span>{rotatingPrices[0]}EUR/</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pricing-botom-txt">
                                                <h6 style={{height: 0}}>&nbsp;</h6>
                                                <a href="https://app.creativeproxies.com/subscribe"><button type="button" className="btn btn-plan">
                                                    Purchase Plan <img src="/static/images/arrow-right.svg" alt="a"
                                                                       className="img-fluid"/>
                                                </button></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4">
                                        <div className="pricing-box-wrap custom-price-box best-value">
                                            <span className="best-value">BEST VALUE</span>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="price-header">
                                                        <span>ROTATING</span>
                                                        <h3>1 Month</h3>
                                                        <h5>30 Days Access</h5>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="price-header-premium">
                                                        <br/>
                                                        <br/>
                                                        <p>FROM</p>
                                                        <p>
                                                            <span>{rotatingPrices[2]}EUR/</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pricing-botom-txt">
                                                <h6 style={{height: 0}}>&nbsp;</h6>
                                                <a href="https://app.creativeproxies.com/subscribe"><button type="button" className="btn btn-plan">
                                                    Purchase Plan <img src="/static/images/arrow-right.svg" alt="a"
                                                                       className="img-fluid"/>
                                                </button></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4">
                                        <div className="pricing-box-wrap custom-price-box">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="price-header">
                                                        <span>ROTATING</span>
                                                        <h3>1 Week</h3>
                                                        <h5>7 Days Access</h5>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="price-header-premium">
                                                        <br/>
                                                        <br/>
                                                        <p>FROM</p>
                                                        <p>
                                                            <span>{rotatingPrices[1]}EUR/</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pricing-botom-txt">
                                                <h6 style={{height: 0}}>&nbsp;</h6>
                                                <a href="https://app.creativeproxies.com/subscribe"><button type="button" className="btn btn-plan">
                                                    Purchase Plan <img src="/static/images/arrow-right.svg" alt="a"
                                                                       className="img-fluid"/>
                                                </button></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-5"/>
                                <div className="price-range-static range-route">
                                    <span>STATIC</span>
                                    <div className="d-block">
                                        <label htmlFor="customRange1" className="form-label">
                                            NUMBER OF IPs {ips}
                                        </label>
                                        <input max={maxIPs} onChange={(event) => {
                                            let val = Number(event.target.value)
                                            setIPs(val)
                                            calcStaticPrices(val)

                                        }} value={ips} step={5} type="range" className="form-range" id="customRange1"/>
                                    </div>
                                </div>
                                <div className="row routing-price">
                                    <div className="col-12 col-md-6 col-lg-4">
                                        <div className="pricing-box-wrap custom-price-box">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="price-header">
                                                        <span>STATIC</span>
                                                        <h3>1 Day</h3>
                                                        <h5>24 Hours Access</h5>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="price-header-premium">
                                                        <br/>
                                                        <br/>
                                                        <p>FROM</p>
                                                        <p>
                                                            <span>{staticPrices[0]}EUR/</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pricing-botom-txt">
                                                <h6 style={{height: 0}}>&nbsp;</h6>
                                                <a href="https://app.creativeproxies.com/subscribe"><button type="button" className="btn btn-plan">
                                                    Purchase Plan <img src="/static/images/arrow-right.svg" alt="a"
                                                                       className="img-fluid"/>
                                                </button></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4">
                                        <div className="pricing-box-wrap custom-price-box best-value best-value-2">
                                            <span className="best-value">BEST VALUE</span>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="price-header">
                                                        <span>STATIC</span>
                                                        <h3>1 Month</h3>
                                                        <h5>30 Days Access</h5>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="price-header-premium">
                                                        <br/>
                                                        <br/>
                                                        <p>FROM</p>
                                                        <p>
                                                            <span>{staticPrices[2]}EUR/</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pricing-botom-txt">
                                                <h6 style={{height: 0}}>&nbsp;</h6>
                                                <a href="https://app.creativeproxies.com/subscribe"><button type="button" className="btn btn-plan">
                                                    Purchase Plan <img src="/static/images/arrow-right.svg" alt="a"
                                                                       className="img-fluid"/>
                                                </button></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4">
                                        <div className="pricing-box-wrap custom-price-box">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="price-header">
                                                        <span>STATIC</span>
                                                        <h3>1 Week</h3>
                                                        <h5>7 Days Access</h5>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="price-header-premium">
                                                        <br/>
                                                        <br/>
                                                        <p>FROM</p>
                                                        <p>
                                                            <span>{staticPrices[1]}EUR/</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pricing-botom-txt">
                                                <h6 style={{height: 0}}>&nbsp;</h6>
                                                <a href="https://app.creativeproxies.com/subscribe"><button type="button" className="btn btn-plan">
                                                    Purchase Plan <img src="/static/images/arrow-right.svg" alt="a"
                                                                       className="img-fluid"/>
                                                </button></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* pricing section @E */}
                {/* customer feedback @S */}
                <section className="customer-feedback pa-y4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="common-heading">
                                    <h2>
                                        Customers <span className="grn">Love</span> our Proxies
                                    </h2>
                                    <p>
                                        We have a satisfaction rate of over 98% <br/> You will not be disappointed!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-4">
                                <div className="feedback-wrap">
                                    <div className="d-flex">
                                        <ul>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fa fa-star-half"/>
                                            </li>
                                        </ul>
                                    </div>
                                    <p>
                                        "good priced proxy provider, can recommend"
                                    </p>
                                    <div className="media">
                                        <img src="/static/images/fraft.webp" alt="Avatar" style={{"maxWidth": "20%"}}
                                             className="img-fluid"/>
                                        <div className="media-body">
                                            <h5>Friedrich Kräft</h5>
                                            <h6>CEO, 3xK Tech GmbH</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4">
                                <div className="feedback-wrap">
                                    <div className="d-flex">
                                        <ul>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                        </ul>
                                    </div>
                                    <p>
                                        “I have been utilizing CreativeProxies as my exclusive proxy service provider
                                        for an extended period. Their reliable performance and prompt customer support
                                        have consistently met my needs without any problems.”
                                    </p>
                                    <div className="media">
                                        <img src="/static/images/paulo.jpg" alt="Avatar"
                                             style={{"maxWidth": "20%", borderRadius: "50px"}} className="img-fluid"/>
                                        <div className="media-body">
                                            <h5>Paulo</h5>
                                            <h6>Customer</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4">
                                <div className="feedback-wrap">
                                    <div className="d-flex">
                                        <ul>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                            <li>
                                                <i className="fa fa-star"/>
                                            </li>
                                        </ul>
                                    </div>
                                    <p>
                                        “Thanks to CreativeProxies and their staff, always have given me best solutions
                                        for my business”
                                    </p>
                                    <div className="media">
                                        <img src="/static/images/singh.jpg" alt="Avatar"
                                             style={{"maxWidth": "20%", borderRadius: "50px"}} className="img-fluid"/>
                                        <div className="media-body">
                                            <h5>Suresh Singh</h5>
                                            <h6>Social Media Specialist, OverView Marketing Co</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* customer feedback @E */}
            </div>
            <Footer/>
        </>
    );
}

export default PricingCustom;
