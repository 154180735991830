import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

function LocationCheckerError() {
    return (
        <>
            {" "}
            <Header/>
            <section className="location-checker-section pa-y4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common-heading">
                                <p className="m-0 mb-1">IP LOCATION CHECKER</p>
                                <h2>
                                    Check Locations of all your <br/>
                                    Proxies <span className="blu">Free</span> of Cost
                                </h2>
                                <p>
                                    To Check location, enter the address or proxies you <br/> want to check (1 IP Per
                                    line)
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <div className="location-checker-tool-wrap location-error">
                                <form action>
                                    <div className="form-group">
                                        <textarea className="form-control" defaultValue={""}/>
                                    </div>
                                    <div className="d-flex">
                                        <div>
                                            <button className="btn btn-submit" type="submit">
                                                Check Location{" "}
                                            </button>
                                            <span className="error-message">Please Enter Valid Input</span>
                                        </div>
                                        <p>
                                            By using our tool, you agree our <br/>
                                            <a href="/Legal.jsx"> Terms of Service</a> and <a href="/privacy">Privacy
                                            Policy</a>
                                        </p>
                                    </div>
                                </form>
                            </div>
                            <div className="netowork-info-table-box location-check-table">
                                <h5>Your Results</h5>
                                <table>
                                    <tbody>
                                    <tr>
                                        <th>IP</th>
                                        <th>PROXY</th>
                                        <th>CITY</th>
                                        <th>COUNTRY</th>
                                    </tr>
                                    <tr>
                                        <td colSpan={4} rowSpan={4} style={{textAlign: "center", height: 120}}>
                                            No Records to Display
                                        </td>
                                    </tr>
                                    <tr/>
                                    <tr/>
                                    <tr/>
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-more">
                                <p>
                                    Check out our IPv6 Compatibility Checker{" "}
                                    <a href="#">
                                        View Tool <img src="/static/images/arrow-right-blu.svg" alt="a"
                                                       className="img-fluid"/>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
}

export default LocationCheckerError;
