import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

function Imprint() {
    return (
        <>
            {" "}
            <Header/>
            <section className="imprint-section pa-y4 pt-0">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="common-heading text-start">
                                <h2>Company Imprint</h2>
                                <p>Learn more about Creative Proxies</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-6">
                            <div className="imprint-txt-box">
                                <h5>Business/Trade Name</h5>
                                <p>Kew Solutions, Unipessoal Lda.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6">
                            <div className="imprint-txt-box">
                                <h5>Registered Business Address</h5>
                                <p>
                                    Avenida da Forca Aerea Portuguesa N14 <br/>
                                    3800-356 Aveiro, Portugal
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6">
                            <div className="imprint-txt-box">
                                <h5>Contact Email</h5>
                                <p>
                                    <a href="mailto:hello@creativeproxies.com">hello@creativeproxies.com</a>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6">
                            <div className="imprint-txt-box">
                                <h5>Contact Phone Number</h5>
                                <p>+351 928022089</p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6">
                            <div className="imprint-txt-box">
                                <h5>Chamber of Commerce Registration Number</h5>
                                <p>PT516683900</p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6">
                            <div className="imprint-txt-box">
                                <h5>VAT ID</h5>
                                <p>PT516683900</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
}

export default Imprint;
