import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import "./index.css";
import "./assets/css/responsive.css";
import "./assets/css/style.css";
import {QueryClientProvider} from "react-query";
import axios from "axios";
import {queryClient} from "./api/query";

axios.defaults.baseURL = "https://api.creativeproxies.com"
axios.defaults.withCredentials = true
ReactDOM.render(
    <BrowserRouter>
        <QueryClientProvider client={queryClient}>
            <App/>
        </QueryClientProvider>
    </BrowserRouter>,

    document.getElementById("root")
);
