import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

function NetworkInformation() {
    return (
        <>
            {" "}
            <Header/>
            <section className="nwtwork-info-section pa-y4 pt-0">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="common-heading text-start">
                                <h2>Network Information</h2>
                                <p>All the required Network Details related to Creative Proxies</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-md-12">
                            <div className="netowork-info-table-box">
                                <h5>Points of Presence</h5>
                                <table>
                                    <tbody>
                                    <tr>
                                        <th>NAME</th>
                                        <th>ADDRESS</th>
                                        <th>NETWORK CAPACITY</th>
                                    </tr>
                                    <tr>
                                        <td>Equinix: TR2 Toronto Data Centers</td>
                                        <td>Toronto, Canada</td>
                                        <td>40Gbps</td>
                                    </tr>
                                    <tr>
                                        <td>CreativeProxies: PO1 Porto Data Centers</td>
                                        <td>Porto, Portugal</td>
                                        <td>10Gbps</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-12">
                            <div className="netowork-info-table-box">
                                <h5>Internet Exchanges</h5>
                                <table>
                                    <tbody>
                                    <tr>
                                        <th>LOCATION</th>
                                        <th>CAPACITY</th>
                                    </tr>
                                    <tr>
                                        <td>ONIX</td>
                                        <td>10 Gbps</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-12">
                            <div className="imprint-txt-box">
                                {false &&
                                    <>
                                        <h5>Notes </h5>
                                        <p>
                                            Ut molestie eros metus, mollis ultrices nisi pretium lacinia. Maecenas
                                            vestibulum erat diam, nec tincidunt elit interdum nec. Proin
                                            placerat leo et rutrum tempus. Duis posuere odio eget sapien commodo, nec
                                            posuere nunc malesuada. Praesent blandit condimentum
                                            porta. Nulla ullamcorper mattis mi eget cursus. Aliquam odio tortor, euismod
                                            vel sagittis vel, dapibus at elit.
                                        </p></>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
}

export default NetworkInformation;
